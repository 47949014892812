const initSlider = (container) => {
  const slider = container.querySelector('.promotion');
  const arrowLeft = container.querySelector('.arrow');
  const arrowRight = container.querySelector('.arrow-right');
  let pos = { top: 0, left: 0, x: 0, y: 0 };

  const bindEvents = () => {
    arrowLeft?.addEventListener('click', () => horizontalScroll('back'));
    arrowRight?.addEventListener('click', horizontalScroll);
    window.addEventListener('resize', renderArrows);
    slider.addEventListener('scroll', renderArrows);
    slider.addEventListener('mousedown', mouseDownHandler);
  };

  const mouseDownHandler = function (e) {
    pos = {
      // The current scroll
      left: slider.scrollLeft,
      top: slider.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  const mouseMoveHandler = function (e) {
    slider.querySelectorAll('a').forEach((link) => {
      link.style.pointerEvents = 'none';
    });
    // How far the mouse has been moved
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    // Scroll the element
    slider.scrollTop = pos.top - dy;
    slider.scrollLeft = pos.left - dx;
  };

  const mouseUpHandler = function () {
    slider.querySelectorAll('a').forEach((link) => {
      link.style.pointerEvents = 'auto';
    });
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    slider.style.cursor = 'grab';
    slider.style.removeProperty('user-select');
  };

  const horizontalScroll = (direction) => {
    scrollAmount = 0;
    var slideTimer = setInterval(function () {
      if (direction === 'back') {
        slider.scrollLeft -= 70;
      } else {
        slider.scrollLeft += 70;
      }

      setArrowsFromScrollStates();
      scrollAmount += 70;
      if (scrollAmount >= 500) {
        window.clearInterval(slideTimer);
      }
    }, 27);
  };

  const renderArrows = () => {
    if (slider.scrollWidth > slider.clientWidth) {
      setArrowsFromScrollStates();
      Array.from(slider.querySelectorAll('.price')).forEach((card) => {
        card.classList.add('card-spacing');
      });
    } else {
      arrowLeft?.classList.add('disabled');
      arrowRight?.classList.add('disabled');
      Array.from(slider.querySelectorAll('.price')).forEach((card) => {
        card.classList.remove('card-spacing');
      });
    }
  };

  const setArrowsFromScrollStates = () => {
    if (slider.scrollWidth - 1 < slider.clientWidth + slider.scrollLeft) {
      arrowRight?.classList.add('disabled');
    } else {
      arrowRight?.classList.remove('disabled');
    }
    if (slider.scrollLeft === 0) {
      arrowLeft?.classList.add('disabled');
    } else {
      arrowLeft?.classList.remove('disabled');
    }
  };

  bindEvents();
  renderArrows();
};

document.addEventListener('DOMContentLoaded', function () {
  Array.from(document.querySelectorAll('.promotion-container')).forEach((container) => {
    initSlider(container);
  });
});
